import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = `${ environment.base_url }/ImagenesBD`;

@Injectable({
  providedIn: 'root'
})
export class ImagenesService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  constructor(private http: HttpClient) { }

  getAll(coleccion:string = 'imagenes'){
    const url = `${ base_url }/getAll/${coleccion}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                                                   
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getImagenesMatricula(matricula:string){
    const url = `${ base_url }/getImagenesMatricula/${matricula}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                                                   
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getUltimoOCR(){
    const url = `${ base_url }/getUltimoOCR`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                                                   
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarImagen(id:number){
    const url = `${ base_url }/borrarImagen/${id}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
}
