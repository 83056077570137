import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LiPresupuestos } from '../interfaces/liPresupuestos.interface';
import { Presupuesto } from '../interfaces/presupuesto.interface';

const base_url = `${ environment.base_url }/Presupuestos`;


@Injectable({
  providedIn: 'root'
})
export class PresupuestosService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  constructor(private http: HttpClient) { }

  
  nuevo(data:Presupuesto){
    const url = `${ base_url }/postPresupuesto`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getEsProforma(id:number){
    const url = `${ base_url }/esProforma/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getPresupuestoId(id:number){
    const url = `${ base_url }/getPresupuestoId/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getPresupuestoNumero(numero: number, ano:number, serie: number){
    const url = `${ base_url }/getPresupuestoNumero/${numero}/${ano}/${serie}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizar(data:Presupuesto, id:number){
    const url = `${ base_url }/putPresupuesto/${id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarPresupuesto(id:number){
    const url = `${ base_url }/borrarPresupuesto/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAll(pagina:number =1, rows:number = 15, desdeNumero:number = 0, hastaNumero: number = 9999999999, desdeSerie: string = '', hastaSerie: string = environment.marcaFinal, desdeCodigoCliente:string = '', hastaCodigoCliente:string = environment.marcaFinal, desdeNombreFiscal:string = '', hastaNombreFiscal:string = environment.marcaFinal, desdeFecha: Date, hastaFecha: Date, factura0: boolean = false, orden:string = 'numeroAlbaran', desc:number = 0){
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&denum=${desdeNumero}&hanum=${hastaNumero}&deser=${desdeSerie
                  }&haser=${hastaSerie}&decli=${desdeCodigoCliente}&hacli=${hastaCodigoCliente}&defis=${desdeNombreFiscal
                  }&hafis=${hastaNombreFiscal}&defec=${desdeFecha}&hafec=${hastaFecha}&fac0=${factura0}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevoLiPresupuestosHis(data:LiPresupuestos){
    const url = `${ environment.base_url }/HistorialLineas/postLiPresupuestoHis`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getLiPresupuestosHis(id:number){
    const url = `${ environment.base_url }/HistorialLineas/getAllLiPresupuestosHis/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarFacturaEnPresupuesto(idFactura: number, idPresupuesto: number){
    const url = `${ base_url }/actualizarFacturaEnPresupuesto/${idFactura}/${idPresupuesto}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarOrdenEnPresupuesto(idOt: number, idPresupuesto: number){
    const url = `${ base_url }/actualizarOrdenEnPresupuesto/${idOt}/${idPresupuesto}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarAlbaranEnPresupuesto(idAlbaran: number, idPresupuesto: number){
    const url = `${ base_url }/actualizarAlbaranEnPresupuesto/${idAlbaran}/${idPresupuesto}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  grabarLineasDirectamente(data:Presupuesto){
    const url = `${ base_url }/grabarLineasDirectamente`;
       
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  
  busqueda(valor:string, pagina:number, rows:number){
    const url = `${ base_url }/getBusquedaPresupuesto/${valor}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
}
